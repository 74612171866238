

export default async function slideIt(path, delay = 10000) {
  const $sliders = document.querySelector(path)
  const $slides = Array.from($sliders.querySelectorAll('img'))
  const total = $slides.length

  let current = 1
  
  $slides[0].style.zIndex = 2

  $slides.forEach(($slide) => {
    $slide.style.visibility = 'hidden'
  })

  await Promise.all(
    $slides
      .filter((img) => !img.complete)
      .map(img => new Promise(resolve => { img.onload = img.onerror = resolve; })))

  $slides.forEach(($slide) => {
    $slide.style.visibility = 'visible'
  })

  setInterval(() => {
    $slides.forEach(($slide, i) => {
      $slide.style.zIndex = 1
      $slide.hidden = true
      $slide.classList.remove('enter-active', 'enter-to', 'leave-active', 'leave-to')

      if (i === current) {
        $slide.style.zIndex = 2
        $slide.hidden = false
        $slide.classList.add('enter-from')

        setTimeout(() => {
          $slide.classList.remove('enter-from')
          $slide.classList.add('enter-active', 'enter-to')
        }, 100)
      } else if (i === (current + total - 1) % total) {
        $slide.hidden = false
        $slide.classList.add('leave-from')

        setTimeout(() => {
          $slide.classList.remove('leave-from')
          $slide.classList.add('leave-active', 'leave-to')
        }, 100)
      }
    })

    current = (current + 1) % total
  }, delay)
}