{
  "2024-08-22": {
    "text": "Ouvert le soir",
    "place": "Flânerie Nocturne Grasse",
    "link": "https://www.grasse.fr/DP_ete_2024.pdf"
  },
  "2024-08-29": {
    "text": "Ouvert le soir",
    "place": "Flânerie Nocturne Grasse",
    "link": "https://www.grasse.fr/DP_ete_2024.pdf"
  },
  "2024-12-15": {
    "text": "Rendez-vous",
    "place": "Marché de Noël Cabris",
    "link": "https://www.facebook.com/Officedetourismecabris"
  },
  "2024-12-16": {
    "text": "Rendez-vous",
    "place": "Marché de Noël Cabris",
    "link": "https://www.facebook.com/Officedetourismecabris"
  }
}