import news from '../news.json'

const DAYS_BEFORE = 50

const $news = document.querySelector('#news')
const $text =  $news.querySelector('[data-text]')
const $day =  $news.querySelector('[data-day]')
const $month =  $news.querySelector('[data-month]')
const $year =  $news.querySelector('[data-year]')
const $place =  $news.querySelector('[data-place]')
const $link =  $news.querySelector('[data-link]')

export default function initNews() {
  let i = 0
  
  for(const [ date, item ] of Object.entries(news)) {
    const eventDate = new Date(date).setHours(23, 59, 59, 999)
    const today = new Date()

    i++

    if (eventDate >= today && eventDate - DAYS_BEFORE * 24 * 60 * 60 * 1000 <= today) {
      $text.textContent = item.text
      $place.textContent = item.place
      $link.href = item.link 
      $day.textContent = new Date(date).getDate()
      $month.textContent = new Date(date).toLocaleString('fr', { month: 'long' })
      $year.textContent = new Date(date).getFullYear()

      if (i % 2 === 0) {
        $news.classList.add('secondary')
      } else {
        $news.classList.add('primary')
      }

      return
    }
  }

  $news.remove()
}